import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const Contact = (props)=>  {


    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);




        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Term & Conditions</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "80px 30px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div style={{fontWeight :"bold"}}>
                                <h2>Terms & Conditions</h2>

<p><strong>OVERVIEW</strong></p>

<p>This website is operated by Swifty kart. Throughout the site, the terms “we”, “us” and “our” refer to Swifty kart. Swifty kart offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>

<h3>Terms & Conditions for Online Sale</h3>

<p>No monetary refunds will be entertained; all items can be exchanged in case of any defects.</p>

<p>Customer can place 30 to 40 orders within a 24-hour window.</p>

<p>The minimum order value for delivery is PRS. 3,000. Orders below this value will not be processed.</p>

<p>Maximum order value for Cash on Delivery orders is PRS. 5,00,000. For orders above this limit, please use any of our prepaid payment options.</p>

<br/>

<p>If you have any questions or require further assistance, please don't hesitate to contact us.</p>

                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;