import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';

import swal from 'sweetalert';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import RemoveRedEyeSharpIcon from '@material-ui/icons/RemoveRedEyeSharp';

import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import FavoriteIcon from '@material-ui/icons/Favorite';

import logo1 from '../assets/images/logo1.png';



const SearchData = (props) =>{
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [Subproduct1 , setSubproduct1] = useState([])
    const [dispaluy53 , setdispaluy53] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    useEffect(() =>{
        window.scrollTo(0, 0)

        var data2 = JSON.parse(localStorage.getItem("SearchData")) 
        
            setProduct(data2)
            fetch("https://zain-ecommerce-main-back.vercel.app/AllCategories",{
              method: "GET",
               headers :  {
               "Content-Type" : "application/json" , 
           } ,
          })
          .then(res2=>res2.json())
          .then(res3=>{
              // console.log(res3);
              // const Data1 = res3.filter((res4,i)=>{
              //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
              //     return res4
              // })
              // console.log(Data1,res3)
              setSubCategories(res3)
              // setSubCategoriesname(res3)
              fetch("https://zain-ecommerce-main-back.vercel.app/MainCatogories",{
                method: "GET",
                 headers :  {
                 "Content-Type" : "application/json" , 
             } ,
            })
            .then(res12=>res12.json())
            .then(res13=>{
              let a = []
              // console.log(res3)
              for (let i = 0; i < res13.length; i++) {
              for (let j = 0; j < res3.length; j++) {
                // console.log(res3[j].MainCategories , res13[i].MainCategories)
                if(res3[j].MainCategories === res13[i].MainCategories){
                  // a.push("1")
    // console.log(i,j)
                  a[i] = "1"
                  break
                }
                else{
                  a[i] = "0"
                  // a.pusH(("0"))
                }
                
              }
              }
              // console.log(a)
              setCate1(a)
            })
          })
    
    
          fetch("https://zain-ecommerce-main-back.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            // console.log(data);
            setSubClassCategories(data)


            fetch("https://zain-ecommerce-main-back.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          console.log(res3,res13)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
            if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
              // a.push("1")
console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          console.log(a)
          setCate2(a)
        })
        })
            fetch("https://zain-ecommerce-main-back.vercel.app/MainCatogories",{
                method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
            })
            .then(res4=>res4.json())
            .then(res5=>{
                setCategories(res5)
                setMainCatogories(res5)
                // console.log(res1);
            })


            fetch("https://zain-ecommerce-main-back.vercel.app/TagsSortArrival1111",{
                method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
            })
            .then(res9=>res9.json())
            .then(res8=>{
                // const dta = res8.filter((res7,i)=>{
                //     if(res7.MainCategories ===Categoriesname.categoriesName  && res7.New_Sub_Categories === Categoriesname.subcategories) {
                //         console.log(res7);
                //         return res7
                //     }
                // })
                // console.log(dta);
                setSortedData(res8)
            })
 const da = setInterval(()=>{
             var data2 = JSON.parse(localStorage.getItem("SearchData")) 
            setProduct(data2)
        },3000)
    return () => clearInterval(da);


},[])






const savethedetailproduct = (data) =>{
    localStorage.setItem("Data" , JSON.stringify(data) )
    console.log(data);
 }


 const setSortPrice1 = (e)=>{
  setdispaluy53(!dispaluy53)
  if(Product.length !== 0){

    SortedName.push(e)
    console.log(e,Product);
    if (e === "Low"){
        const Pro6 = Product.sort(function (a, b) {
                return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
              });
              console.log(Pro6);
              let reversed_array = [];
              Pro6.forEach((element) => {
                reversed_array.unshift(element);
            });
              console.log(Pro6,reversed_array)
              setProduct(reversed_array)
              setProduct(Pro6)
              setdispaluy53(!dispaluy53)
              setdispaluy53(!dispaluy53)

    }
    if (e === "High"){
        const Pro6 = Product.sort((a, b)=> {
                return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                
            });
          //   let reversed_array = [];
          //   Pro6.forEach((element) => {
          //     reversed_array.unshift(element);
          // });
          //   console.log(Pro6,reversed_array)
            setProduct(Pro6)
            setdispaluy53(!dispaluy53)
            setdispaluy53(!dispaluy53)

    }
    if (e === "A"){
        const Pro6 = Product.sort((a, b)=> {
            var nameA = a.Product_Name.toUpperCase(); 
            var nameB = b.Product_Name.toUpperCase(); 
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
            });
            console.log(Pro6);
            setProduct(Pro6)
            
          }
          
          setProduct([])
          setdispaluy53(!dispaluy53)

        }
        else{
    
            SortedName.push(e)
            console.log(e);
            if (e === "Low"){
                const Pro6 = Product.sort((a, b)=> {
                        return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                        
                    });
                    setProduct1(Pro6)
        
            }
            if (e === "High"){
                const Pro6 = Product.sort((a, b)=> {
                        return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                        
                    });
                    setProduct(Pro6.reverse())
        
            }
            if (e === "A"){
                const Pro6 = Product.sort((a, b)=> {
                    var nameA = a.Product_Name.toUpperCase(); 
                    var nameB = b.Product_Name.toUpperCase(); 
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
        
                    return 0;
                    });
                    console.log(Pro6);
                    setProduct(Pro6)
                    
                  }
                  
                  
                  setProduct([])
                }
                setdispaluy53(!dispaluy53)

              }


const SortData1 = (a ,b) =>{
            setFal1(false)

          const Pro6 = Product.sort((a, b)=> {
                return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                
            });
            setProduct1(Pro6)
            setFal(true)
            console.log(Product1);



            
}
const SortData2 = (a ,b) =>{
    setFal(false)
          const Pro6 = Product.sort((a, b)=> {
            var nameA = a.Product_Name.toUpperCase(); 
            var nameB = b.Product_Name.toUpperCase(); 
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
            });
            setProduct2(Pro6)
            setFal1(true)
            console.log(Product2);



            
}


// const cate =(Categories) =>{
//     var Cat1 = Categories.split(" ").join("-")

//     localStorage.setItem("Cate" , JSON.stringify(Categories) )
    
//     setTimeout(()=>{
//         props.history.push(`/shop/categories/Rs.{Cat1}`)
//     },1500)
// }



const sortedAlgo = (e)=>{
    let d = false
    let da = 0
    SortedName.map((res9,a)=>{
        if(res9 === e){
            d = true
            da = a
        }
    })
    if(d){
        SortedName.splice(da,1)
    }
    else{
        SortedName.push(e)
    }
    fetch("https://zain-ecommerce-main-back.vercel.app/AllProduct",{
                    method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                })
                .then(res7=>res7.json())
                .then(res8=>{
                    // setallproduct(res8)
                    const NonActive = res8.filter((res9,i)=>{
                        // console.log(res9.status , res9.Product_Catagories , CategoriesName.categoriesName  ,res9.Product_Sub_Catagories , Categoriesname.subcategories);
                        if(res9.status && res9.Arrival){
                            return res9 
                        }
                    })
                
    if(SortedName.length > 0){
        let Coa = []
        SortedName.map((res22,x)=>{
      console.log(res22,SortedName)
//    console.log(Subproduct)
   const Sort = NonActive.filter((res,i)=>{
        res.Product_Name.split(" ").map((res1,i)=>{
            // console.log(res1)
            if (res1 === res22){
                Coa.push(res)
            }
        })
        res.Product_Title.split(" ").map((res1,i)=>{
            // console.log(res1)
            if (res1 === res22){
                Coa.push(res)
            }
        })
        
            res.tags.map((res1,i)=>{
                if (res1 === res22){
                    // console.log("true")
                    Coa.push(res)
                }
            })
        // }
   })  
    })
     let uniq = [...new Set(Coa)];

   console.log(uniq);
   setProduct(uniq)
   localStorage.setItem("SearchData",JSON.stringify(uniq))

}
else{
    setProduct(NonActive)
    localStorage.setItem("SearchData",JSON.stringify(NonActive))

    }
   
  })
}


const addtocartproduct = (productDetail) =>{
    swal("Update!", "Your Item Add in Cart!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  
    const setSortPrice = (e)=>{
        // setFal(false)
        SortedName.push(e)
        console.log(e);
        if (e === "Low"){
            const Pro6 = Product.sort((a, b)=> {
                    return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                    
                });
                setProduct(Pro6)

        }
        if (e === "A"){
            const Pro6 = Product.sort((a, b)=> {
                var nameA = a.Product_Name.toUpperCase(); 
                var nameB = b.Product_Name.toUpperCase(); 
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
                });
                console.log(Pro6);
                setProduct(Pro6)

        }
        setFal(true)
    }



    
    
    const setDisplayBlock = ()=>{
        // console.log("fddddddddddddddddd")
       let data1 =  document.getElementById("dispaly-block")
       if(data1.style.display === "none"){
        data1.style.display = "block"
       }
       else{
        data1.style.display = "none"
       }
    }



    const addtocartproduct8 = (productDetail) =>{
      swal("Update!", "Item Save!", "success");
    
          
      // document.getElementById("myDi").style.visibility = "visible"
      // setTimeout(()=>{
      // document.getElementById("myDi").style.visibility = "hidden"
    
      // },1000)
      console.log(productDetail)
      if (productDetail[0].Product_Price_Discounted === null){
      const data =  {...productDetail[0] ,
          Percentage : 0,
          DeliveryStatus : "Pending",
          Pieces : 1 ,
          Total_Product_Price  : productDetail[0].Product_Price * 1 }
      var data1 = JSON.parse(localStorage.getItem("Fav")) 
      if (data1){
      var data3 = data1.map((item) => {
          if(item._id === data._id ){
              console.log("double");
      ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
      }
      else{
      console.log("double not match");
      return item
      }
    
      })
      var data5 =  JSON.parse(localStorage.getItem("double")) 
      console.log(DataPart2.length, data3.length,data5);
      var data10 =  JSON.parse(localStorage.getItem("Fav")) 
    
      if(data10.length=== data3.length && data5){
      console.log("double remove");
      localStorage.removeItem("double")
      localStorage.setItem("Fav" , JSON.stringify(data3) )
    
      }
      else{
      console.log("Differet");
      var data2 = [...data1 , data]
    
      localStorage.setItem("Fav" , JSON.stringify(data2) )
      }
      }
      else{
      console.log("1");
      localStorage.setItem("Fav" , JSON.stringify([data]) )
    
      }
    
      }
      else if (productDetail[0].Product_Price_Discounted){
      const data =  {...productDetail[0] ,
          Percentage : 0,
          DeliveryStatus : "Pending",
          Pieces : 1 ,
          Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
      var data1 = JSON.parse(localStorage.getItem("Fav")) 
      if (data1){
      var data3 = data1.map((item) => {
          if(item._id === data._id ){
              console.log("double");
      ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
      }
      else{
      console.log("double not match");
      return item
      }
    
      })
      var data5 =  JSON.parse(localStorage.getItem("double")) 
      console.log(DataPart2.length, data3.length,data5);
      var data10 =  JSON.parse(localStorage.getItem("Fav")) 
    
      if(data10.length=== data3.length && data5){
      console.log("double remove");
      localStorage.removeItem("double")
      localStorage.setItem("Fav" , JSON.stringify(data3) )
    
      }
      else{
      console.log("Differet");
      var data2 = [...data1 , data]
    
      localStorage.setItem("Fav" , JSON.stringify(data2) )
      }
      }
      else{
      console.log("1");
      localStorage.setItem("Fav" , JSON.stringify([data]) )
    
      }
    
      }
      else if (productDetail[0].Size_Discounted[0]){
          
              const data =  {...productDetail[0] ,
                  Percentage : 0,
                  DeliveryStatus : "Pending",
                  Pieces : 1 ,
                  Size : productDetail[0].Size[0],
                  Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                  Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
      var data1 = JSON.parse(localStorage.getItem("Fav")) 
      if (data1){
      var data3 = data1.map((item) => {
          if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
              console.log("double");
      ;                   localStorage.setItem("double",JSON.stringify(true))
          return {...item,
              Pieces : 1 + item.Pieces,
              Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
      }
      else{
          console.log("double not match");
          return item
      }
    
      })
      var data5 =  JSON.parse(localStorage.getItem("double")) 
      console.log(DataPart2.length, data3.length,data5);
      var data10 =  JSON.parse(localStorage.getItem("Fav")) 
    
      if(data10.length=== data3.length && data5){
      console.log("double remove");
      localStorage.removeItem("double")
      localStorage.setItem("Fav" , JSON.stringify(data3) )
    
      }
      else{
      console.log("Differet");
      var data2 = [...data1 , data]
    
      localStorage.setItem("Fav" , JSON.stringify(data2) )
      }
      }
      else{
      console.log("1");
      localStorage.setItem("Fav" , JSON.stringify([data]) )
    
      }
    
      }
      else{
    
    
    
    
              const data =  {...productDetail[0] ,
                  Percentage : 0,
                  DeliveryStatus : "Pending",
                  Pieces : 1 ,
                  Size : productDetail[0].Size[0],
                  Product_Price_Discounted : productDetail[0].Price[0],
                  Total_Product_Price  : productDetail[0].Price[0] * 1 }
      var data1 = JSON.parse(localStorage.getItem("Fav")) 
      if (data1){
      var data3 = data1.map((item) => {
          if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
              console.log("double");
      ;                   localStorage.setItem("double",JSON.stringify(true))
          return {...item,
              Pieces : 1 + item.Pieces,
              Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
      }
      else{
          console.log("double not match");
          return item
      }
    
      })
      var data5 =  JSON.parse(localStorage.getItem("double")) 
      console.log(DataPart2.length, data3.length,data5);
      var data10 =  JSON.parse(localStorage.getItem("Fav")) 
    
      if(data10.length=== data3.length && data5){
      console.log("double remove");
      localStorage.removeItem("double")
      localStorage.setItem("Fav" , JSON.stringify(data3) )
    
      }
      else{
      console.log("Differet");
      var data2 = [...data1 , data]
    
      localStorage.setItem("Fav" , JSON.stringify(data2) )
      }
      }
      else{
      console.log("1");
      localStorage.setItem("Fav" , JSON.stringify([data]) )
    
      }
    
    
      }
    
    
          
    
    }
    const setChan = (i) =>{
      console.log(i)
       if(      document.getElementById("categ"+i).style.display  ==="block")
      document.getElementById("categ"+i).style.display  ="none"
      else
      document.getElementById("categ"+i).style.display  ="block"
    }
    const setChan1 = (i,data) =>{
      //  let  e =  []
      //   for (let s = 0; s < SubCategories.length; s++) {
      //     if(data === SubCategories[s].MainCategories)
      //     e.push( SubCategories[s])
      //   }
      //   console.log(i,e)
      //   // console.log(a)
      //   for (let a = 0; a < e.length; a++) {
      //     console.log(a)
      //      document.getElementById("cate"+a).style.display  ="none"
   
      //    }
      console.log(document.getElementById("cate"+i),i)
         if(      document.getElementById("cate"+i).style.display  ==="block"){
          console.log("yes")
          document.getElementById("cate"+i).style.display  ="none"
        }
        else{
          console.log("yes1")
  
          document.getElementById("cate"+i).style.display  ="block"
        }
            let a =  []
            SubCategories.map((res1,j)=>{
              if(data === res1.MainCategories){
                a.push(j)
              }
            })
            console.log(a)
        if(a[0] !== i){
            document.getElementById("cate"+a[0]).style.display  ="none"
          }
        // if(i !== 0){
      }
    const setChan2 = (i) =>{
      console.log(i)
       if(      document.getElementById("categp"+i).style.display  ==="block")
      document.getElementById("categp"+i).style.display  ="none"
      else
      document.getElementById("categp"+i).style.display  ="block"
    }
    const setChan3 = (i,data) =>{
      // for (let a = 0; a < SubCategories.length; a++) {
      //  console.log(a)
      //   document.getElementById("catep"+a).style.display  ="none"
    
      // }
      console.log(i)
       if(      document.getElementById("catep"+i).style.display  ==="block")
      document.getElementById("catep"+i).style.display  ="none"
      else
      document.getElementById("catep"+i).style.display  ="block"
    
      let a =  []
      SubCategories.map((res1,j)=>{
        if(data === res1.MainCategories){
          a.push(j)
        }
      })
      console.log(a)
    if(a[0] !== i){
      document.getElementById("catep"+a[0]).style.display  ="none"
    }
    }
    const setChang677 = () =>{
      console.log( document.getElementById("navbar-vertical1"))
      if( document.getElementById("navbar-vertical1").style.display === "none")
      document.getElementById("navbar-vertical1").style.display = "block"
      else
      document.getElementById("navbar-vertical1").style.display = "none"
    }
        return (
       


<>
          <main className="main">
          <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">
                {/* {CategoriesName.categoriesName} */}
              <span>Shop</span></h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index-32.html">Home</a></li>
                <li className="breadcrumb-item"><a href="#">Shop</a></li>
                {/* <li className="breadcrumb-item active" aria-current="page">{CategoriesName.categoriesName}</li> */}
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="toolbox">
                    <div className="toolbox-left">
                      <div className="toolbox-info">
                        Showing <span>{Product.length} of {Product.length}</span> Products
                      </div>{/* End .toolbox-info */}
                    </div>{/* End .toolbox-left */}
                    <div className="toolbox-right">
                      <div className="toolbox-sort">
                        <label htmlFor="sortby">Sort by:</label>
                        <div className="select-custom">
                          <select name="sortby" id="sortby" className="form-control">
                            <option value="popularity" selected="selected">Most Popular</option>
                            <option value="Low" onClick={()=>setSortPrice1("Low")}>Low → High</option>
                          <option value="High" onClick={()=>setSortPrice1("High")}>High → Low</option>
                          <option value="A" onClick={()=>setSortPrice1("A")}>A → Z</option>
                            <option value="rating">Most Rated</option>
                            <option value="date">Date</option>
                          </select>
                        </div>
                      </div>{/* End .toolbox-sort */}
                      
                    </div>{/* End .toolbox-right */}
                  </div>{/* End .toolbox */}
                  <div className="products mb-3">
                    <div className="row justify-content-center">



                    {Product.map((res,i)=>{
               if(res.Price[0]){

                return(
<div className="col-6 col-md-4 col-lg-4 col-xl-3">
                      <div className="product product-7 text-center" style={{height : "auto"}}>
                        <figure className="product-media">
                          <span className="product-label label-new">New</span>
                          <Link  to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                          </Link>
                          <div className="product-action-vertical">
                            <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                            <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                            {/* <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a> */}
                          </div>{/* End .product-action-vertical */}
                          <div className="product-action">
                            <a className="btn-product btn-cart"  onClick={()=>addtocartproduct([res])}><span>add to cart</span></a>
                          </div>{/* End .product-action */}
                        </figure>{/* End .product-media */}
                        <div className="product-body">
                          <div className="product-cat">
                            <Link to={"/main-categories/"+res.Product_Catagories}>{res.Product_Catagories}</Link>
                          </div>{/* End .product-cat */}
                          <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden"}}><Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link></h3>{/* End .product-title */}
                          <div className="product-price">
                          
                            {res.Size_Discounted[0]  ? 
                <><span className="product-price">Rs. {res.Size_Discounted[0]}</span><span className="text-muted ml-2" style={{padding :"0px"}}><del className="product-price" style={{color : "grey"}}>Rs. {(res.Price[0])}</del></span></>
                :
                <span>
                Rs. {(res.Price[0])}
                </span> 
                
                                  }   


                          </div>{/* End .product-price */}
                          <div className="product-nav product-nav-thumbs">
                            {res &&res.Product_Image_Upload1
                            ?
                            <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="active">
                              <img src={res.Product_Image_Upload1} alt="product desc" />
                            </Link>
                            : ""
                            
                            }
                            {res &&res.Product_Image_Upload2
                            ?
                            <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} >
                              <img src={res.Product_Image_Upload2} alt="product desc" />
                            </Link>
                            : ""
                            
                            }
                            {res &&res.Product_Image_Upload3
                            ?
                            <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} >
                            <img src={res.Product_Image_Upload3} alt="product desc" />
                          </Link>
                            : ""
                            
                            }

                            
                           
                          </div>{/* End .product-nav */}
                          <div className="product-action position-relative visible">
                        <a className="btn-product btn-cart text-uppercase text-dark text-decoration-none" title="Add to cart" onClick={()=>addtocartproduct([res])}>
                            <span className="text-dark shadow-none">add to cart</span>
                          </a>
                        </div>
                            
                        </div>{/* End .product-body */}
                      </div>{/* End .product */}
                    </div>
)

}

              else{


                if(res.Course_Sub_Catagories !== ""){
                  return(
<div className="col-6 col-md-4 col-lg-4 col-xl-3">
                        <div className="product product-7 text-center" style={{height : "auto"}}>
                          <figure className="product-media">
                            <span className="product-label label-new">New</span>
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                              <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                            </Link>
                            <div className="product-action-vertical">
                              <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                              <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                              {/* <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a> */}
                            </div>{/* End .product-action-vertical */}
                            <div className="product-action">
                              <a className="btn-product btn-cart"  onClick={()=>addtocartproduct([res])}><span>add to cart</span></a>
                            </div>{/* End .product-action */}
                          </figure>{/* End .product-media */}
                          <div className="product-body">
                            <div className="product-cat">
                              <Link to={"/categories/"+res.Product_Catagories}>{res.Product_Catagories}</Link>
                            </div>{/* End .product-cat */}
                            <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden"}}><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link></h3>{/* End .product-title */}
                            <div className="product-price">
                            
                              {res.Product_Price_Discounted ? 
                  <><span className="product-price">Rs. {res.Product_Price_Discounted}</span><span className="text-muted ml-2" style={{padding :"0px"}}><del className="product-price" style={{color : "grey"}}>Rs. {(res.Product_Price)}</del></span></>
                  :
                  <span>
                  Rs. {(res.Product_Price)}
                  </span> 
                  
                                    }   


                            </div>{/* End .product-price */}
                            
                            <div className="product-action position-relative visible">
                          <a className="btn-product btn-cart text-uppercase text-dark text-decoration-none" title="Add to cart" onClick={()=>addtocartproduct([res])}>
                              <span className="text-dark shadow-none">add to cart</span>
                            </a>
                          </div>
                              
                          </div>{/* End .product-body */}
                        </div>{/* End .product */}
                      </div>
 )
}
if(res.Product_Sub_Catagories !== ""){
  console.log("323")
  return(
<div className="col-6 col-md-4 col-lg-4 col-xl-3">
                        <div className="product product-7 text-center" style={{height : "auto"}}>
                          <figure className="product-media">
                            <span className="product-label label-new">New</span>
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                              <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                            </Link>
                            <div className="product-action-vertical">
                              <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                              <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                              {/* <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a> */}
                            </div>{/* End .product-action-vertical */}
                            <div className="product-action">
                              <a className="btn-product btn-cart"  onClick={()=>addtocartproduct([res])}><span>add to cart</span></a>
                            </div>{/* End .product-action */}
                          </figure>{/* End .product-media */}
                          <div className="product-body">
                            <div className="product-cat">
                              <Link to={"/categories/"+res.Product_Catagories}>{res.Product_Catagories}</Link>
                            </div>{/* End .product-cat */}
                            <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden"}}><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link></h3>{/* End .product-title */}
                            <div className="product-price">
                            
                              {res.Product_Price_Discounted ? 
                  <><span className="product-price">Rs. {res.Product_Price_Discounted}</span><span className="text-muted ml-2" style={{padding :"0px"}}><del className="product-price" style={{color : "grey"}}>Rs. {(res.Product_Price)}</del></span></>
                  :
                  <span>
                  Rs. {(res.Product_Price)}
                  </span> 
                  
                                    }   


                            </div>{/* End .product-price */}
                            
                            <div className="product-action position-relative visible">
                          <a className="btn-product btn-cart text-uppercase text-dark text-decoration-none" title="Add to cart" onClick={()=>addtocartproduct([res])}>
                              <span className="text-dark shadow-none">add to cart</span>
                            </a>
                          </div>
                              
                          </div>{/* End .product-body */}
                        </div>{/* End .product */}
                      </div>
   )
  }
  else{
    return(
<div className="col-6 col-md-4 col-lg-4 col-xl-3">
                        <div className="product product-7 text-center" style={{height : "auto"}}>
                          <figure className="product-media">
                            <span className="product-label label-new">New</span>
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                              <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                            </Link>
                            <div className="product-action-vertical">
                              <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                              <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                              {/* <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a> */}
                            </div>{/* End .product-action-vertical */}
                            <div className="product-action">
                              <a className="btn-product btn-cart"  onClick={()=>addtocartproduct([res])}><span>add to cart</span></a>
                            </div>{/* End .product-action */}
                          </figure>{/* End .product-media */}
                          <div className="product-body">
                            <div className="product-cat">
                              <Link to={"/categories/"+res.Product_Catagories}>{res.Product_Catagories}</Link>
                            </div>{/* End .product-cat */}
                            <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden"}}><Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link></h3>{/* End .product-title */}
                            <div className="product-price">
                            
                              {res.Product_Price_Discounted ? 
                  <><span className="product-price">Rs. {res.Product_Price_Discounted}</span><span className="text-muted ml-2" style={{padding :"0px"}}><del className="product-price" style={{color : "grey"}}>Rs. {(res.Product_Price)}</del></span></>
                  :
                  <span>
                  Rs. {(res.Product_Price)}
                  </span> 
                  
                                    }   


                            </div>{/* End .product-price */}
                           
                            <div className="product-action position-relative visible">
                          <a className="btn-product btn-cart text-uppercase text-dark text-decoration-none" title="Add to cart" onClick={()=>addtocartproduct([res])}>
                              <span className="text-dark shadow-none">add to cart</span>
                            </a>
                          </div>
                              
                          </div>{/* End .product-body */}
                        </div>{/* End .product */}
                      </div>

                    )
                  } 
              }
                })
                  
                }

                      
                    </div>{/* End .row */}
                  </div>{/* End .products */}
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className="page-item disabled">
                        <a className="page-link page-link-prev" href="#" aria-label="Previous" tabIndex={-1} aria-disabled="true">
                          <span aria-hidden="true"><i className="icon-long-arrow-left" /></span>Prev
                        </a>
                      </li>
                      <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a></li>
                      {/* <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item-total">of 6</li> */}
                      <li className="page-item">
                        <a className="page-link page-link-next" href="#" aria-label="Next">
                          Next <span aria-hidden="true"><i className="icon-long-arrow-right" /></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>{/* End .col-lg-9 */}
                
              </div>{/* End .row */}
            </div>{/* End .container */}
          </div>{/* End .page-content */}
        </main>{/* End .main */}




</>
        )
    }

export default SearchData
