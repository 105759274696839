import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
// import Shop from './Pages/Shop';
import Contact from './Pages/Contact';
// import Detail from './Pages/Detail';
import Card from './Pages/Card';
import Checkout from './Pages/Checkout';
import Categories from './Pages/Categories';
// import Thankyou from './Pages/Thankyou';
import Header from './Components/Header';
import Footer from './Components/Footer';

// import demo from './Pages/demo';
// import CategoriesShop from './Pages/CategoriesShop';
import SearchData from './Pages/SearchData';

// import Signup from './Pages/Admin/Signup';
// import EditCard from "./Pages/EditCart"
// import CategoriesBook from './Pages/CategoriesBook';
// import CategoriesBags from './Pages/CategoriesBags';
// import CategoriesShoes from './Pages/CategoriesShoes';
// import ShoesProduct from './Pages/ShoesProduct';
// import ShoesProductDetails from './Pages/ShoesProductDetails';
// import SchoolClassProduct from './Pages/SchoolClassProduct';
// import BagsClassProduct from './Pages/BagsClassProduct';
// import chat from './Pages/chat';
// import SignupOtp from './Pages/SignupOtp';


// import whatapps from './assets/images/what.png';






// import SubCategories from './Pages/Categories';
// import SubCategoriesProduct from './Pages/SubCategoriesProduct';












import Privacy from './Pages/Privacy';
import consign from './Pages/consign';
// import Shipping from './Pages/Shipping';
import tracking from './Pages/tracking';
import FAQ from './Pages/FAQ';
import Shop from './Pages/Shop';
// import SaleSubCategoriesProduct from './Pages/SaleSubCategoriesProduct';
// import menSubCategoriesProduct from './Pages/menSubCategoriesProduct';
// import womenSubCategoriesProduct from './Pages/womenSubCategoriesProduct';
import SubCategoriesProduct from './Pages/SubCategoriesProduct';
import SubCategoriesProductDetails from './Pages/SubCategoriesProductDetails';
import SubCategoriesProductDetails1 from './Pages/SubCategoriesProductDetails1';
import SubCategoriesProductDetails2 from './Pages/SubCategoriesProductDetails2';
import SizeProductDetail from './Pages/SizeProductDetail';
import thankyou from './Pages/Thankyou';
import chat from './Pages/chat';
// import WomenOnlySubCategoriesProduct from './Pages/WomenOnlySubCategoriesProduct';
// import MenOnlySubCategoriesProduct from './Pages/MenOnlySubCategoriesProduct';
import SubCategoriesProduct1 from './Pages/SubCategoriesProduct1';
import SubCategoriesProduct2 from './Pages/SubCategoriesProduct2';
import Favorite from './Pages/Favorite';
import whislist from './Pages/whislist';
// import CategoriesUniform from './Pages/CategoriesUniform';
// import UniformClassProduct from './Pages/UniformClassProduct';
// import UniformProductDetails from './Pages/UniformProductDetails';
// import SchoolBookProductDetail from './Pages/SchoolBookProductDetail';
// import BagsProductDetail from './Pages/BagsProductDetail';
// import ArrivalCategoriesProduct from './Pages/ArrivalCategoriesProduct';

import whatapps from './assets/images/what.png';

// import teacherform from './Pages/teacherform';
// import studentform from './Pages/studentform';
import Shipping from './Pages/Shipping';
import Term from './Pages/Term';
import ab from './Pages/ab';
import expertpick from './Pages/expertpick';
import recommendedproduct from './Pages/recommendedproduct';
import newArrival from './Pages/newArrival';
import Error from './Pages/Error';
import submain from './Pages/submain';

// import Privacy from './Pages/Privacy';
// import MainUserSignup from './Pages/MainUserSignup';
// import MainUserLogin from './Pages/MainUserLogin';
// import forgetpassaccount from './Pages/forgetpassaccount';


// import help from './Pages/help';
// import userDashboard from './Pages/userDashboard';
// import EditNameAddress from './Pages/EditNameAddress';
// import userDashOrderDetails from './Pages/userDashOrderDetails';


// import SchoolCategories from './Pages/SchoolCategories';
// import SchoolClassesCategories from './Pages/SchoolClassesCategories';
// import SchoolClassesCategoriesProduct from './Pages/SchoolClassesCategoriesProduct';
// import SchoolClassesCategoriesProductDetail from './Pages/SchoolClassesCategoriesProductDetail';


// import $ from 'jquery';


// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css";
import "./bootstrap.min.css"
import "./all.css"
import "./custom.css"
import "./bootsnav.css"
import "./responsive.css"
import "./style1.css"
import "./css/style.css";
import "./lib/owlcarousel/assets/owl.carousel.min.css";

import "./css1/custom.css";
import "./css1/responsive.css";
import "./css1/bootstrap-select.css";
import "./css1/baguetteBox.min.css";
import "./css1/owl.carousel.min.css";
import "./css1/bootsnav.css";
import "./css1/code_animate.css";
import "./css1/carousel-ticker.css";
import "./css1/superslides.css";
import "./css1/all.css";
import "./css1/style.css";

import "./css1/bootsnav";

import "./asset1/vendor/line-awesome/line-awesome/line-awesome/css/line-awesome.min.css";
import "./asset1/css/bootstrap.min.css";
// import "./asset1/css/plugins/owl-carousel/owl.carousel.css";
import "./asset1/css/plugins/jquery.countdown.css";
import "./asset1/css/plugins/magnific-popup/magnific-popup.css";
import "./asset1/css/style.css";
import "./asset1/css/skins/skin-demo-26.css";
import "./asset1/css/demos/demo-26.css";





function App() {
  
  return (
    <div className="App" >                    
     <a href="https://wa.me/923194911846" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "10px",width:"70px",height : "70px"}}/></a>

      <Router>

        <Header />
        
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/term-policy' component={Term} />
          <Route exact path='/Shipping' component={Shipping} />
          <Route exact path='/product/new-arrival' component={newArrival} />
          <Route exact path='/product/recommended-product' component={recommendedproduct} />
          <Route exact path='/product/expert-pick' component={expertpick} />

          <Route exact path='/shop-all' component={ab} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/favorite' component={Favorite} />
          <Route exact path='/whislist' component={whislist} />
          <Route exact path='/thankyou' component={thankyou} />
          <Route exact path='/consign' component={consign} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/FAQ' component={FAQ} />
          <Route exact path='/chat' component={chat} />
          <Route exact path='/cart' component={Card} />
          <Route exact path='/all-shop' component={Shop} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/about' component={About} />
          <Route exact path='/track' component={tracking} />
          <Route exact path='/search/product-item' component={SearchData} />
          {/* <Route exact path='/ab' component={SubCategoriesProduct} /> */}
          {/* <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct} /> */}
          <Route exact path='/main-categories/:categoriesName' component={Categories} />
          <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct2} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products' component={SubCategoriesProduct1} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products' component={SubCategoriesProduct} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SubCategoriesProductDetails} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products/:productName/:productId' component={SubCategoriesProductDetails2} />
          <Route exact path='/categories/:categoriesName/all-products/:productName/:productId' component={SubCategoriesProductDetails1} />
          {/* <Route exact path='/main-categories/:categoriesName/all-products/:productName/:productId' component={submain} /> */}
          <Route exact path='/main-categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SizeProductDetail} />

          <Route path='*' component={Error} />

        </Switch>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
