import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Ico from './iconBox';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
            {/* End Main Top */}
            {/* Start Top Search */}
            
            {/* End Top Search */}
            {/* Start All Title Box */}
            
            {/* End All Title Box */}
            {/* Start About Page  */}





            <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">ABOUT US</h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index-32.html">Home</a></li>
                <li className="breadcrumb-item"><a href="#">About Us</a></li>
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}



          <div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>


            {/* <h4  style={{marginBottom : "1px"}}>Home Appliances</h4> */}
            <h2>Welcome to Swifty Kart</h2>
    <p>Welcome to Swifty Kart, your ultimate destination for all things trending and must-have! At Swifty Kart, we're passionate about bringing you the latest and hottest products right to your doorstep, no matter where you are in Pakistan. Our mission is simple: to make shopping not just a necessity, but an enjoyable and seamless experience for our valued customers.</p>

    <h2>Why Swifty Kart?</h2>
    <p>Why choose Swifty Kart, you may ask? Well, let us tell you:</p>
    <ul>
        <li><strong>Trending Products, Tailored for You:</strong> We understand that everyone has their own unique style and preferences. That's why we tirelessly scour the market to bring you the trendiest products that cater to your lifestyle and interests. Whether you're into fashion, gadgets, home decor, or wellness essentials, Swifty Kart has got you covered.</li>
        <li><strong>Doorstep Delivery:</strong> Say goodbye to the hassle of traditional shopping! With Swifty Kart, you can sit back, relax, and let us handle the rest. Our hassle-free doorstep delivery service ensures that your purchases are delivered right to your doorstep, so you can enjoy the convenience of shopping from the comfort of your own home.</li>
    </ul>

    <h3>Next Steps:</h3>
    <p>Ready to embark on your Swifty Kart shopping journey? Here's what you can do next:</p>
    <ol>
      
        <li><strong>Explore Our Diverse Product Categories:</strong> Dive into our extensive range of product categories, from fashion and electronics to home essentials and beyond. With something for everyone, you're sure to find exactly what you're looking for (and maybe even a few surprises along the way)!</li>
        <li><strong>Sign Up for Exclusive Deals and Updates:</strong> Don't miss out on our exclusive deals, special promotions, and exciting product updates! Sign up for our newsletter today and be the first to know about all the latest happenings at Swifty Kart.</li>
        <li><strong>Join Our Social Media Community:</strong> Connect with us on social media and become a part of our vibrant community of savvy shoppers! Share your Swifty Kart finds, engage with other shoppers, and stay up-to-date with the latest trends and discussions.</li>
        <li><strong>Contact Our Customer Service Team:</strong> Have questions or need assistance? Our friendly and knowledgeable customer service team is here to help! Whether you need product recommendations, order assistance, or simply want to chat, we're always just a click or a call away.</li>
    </ol>

    <p>At Swifty Kart, we're committed to providing you with a delightful shopping experience from start to finish. So why wait? Start exploring, start shopping, and start enjoying the convenience and excitement of Swifty Kart today!</p>

<p>Enjoy a simplified shopping experience with Swiftykart - your affordable online shopping destination.</p>
            </div>
            </div>
            </div>

            <div className="container icon-boxes-section">
                <div className="icon-boxes-container py-4 bg-lighter mb-2 mt-2">

                  <div>
                 
                 < Ico/>
                

                  </div>

                </div>
                </div>



            {/* </div> */}
        
          </div>
        )
    }
}

export default About;